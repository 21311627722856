import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CustomLayoutComponent} from "./custom-layout/custom-layout.component";
import {TypeFormComponent} from "./pages/type-form/type-form.component";
import {FormSubmittedComponent} from "./form-submitted/form-submitted.component";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/type-form/type-form.module').then(m => m.TypeFormModule),
  },
  { path: 'form-submitted', component: FormSubmittedComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  exports: [RouterModule]
})
export class AppRoutingModule { }
